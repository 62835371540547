import {UPDATE_PREDICTION, FETCH_PREDICTIONS, FETCH_PREDICTION_POINTS} from "./predictionConstants";
import { asyncActionStart, asyncActionFinish, asyncActionError } from "../../app/async/asyncReducer";
import { getAllPredictionsFromFirestore } from "../../app/firestore/firestoreService";


export default function loadPredictions(userId = null) {
    return async function(dispatch) {
      dispatch(asyncActionStart())
      try {
         let predictions = await getAllPredictionsFromFirestore()
         dispatch({type: FETCH_PREDICTIONS, payload: predictions})
         dispatch(asyncActionFinish())
      }
      catch (error) {
          dispatch(asyncActionError(error))
      }
    }
  }

export function listenToPredictions(prediction) {
  return {
    type: FETCH_PREDICTIONS,
    payload: prediction
  }
}

export function listenToPredictionsPoints(predictionPoints) {
  return {
    type: FETCH_PREDICTION_POINTS,
    payload: predictionPoints
  }
}

export function updatePrediction(prediction) {
    return {
        type: UPDATE_PREDICTION,
        payload: prediction
    }
}