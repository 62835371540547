import { useDispatch } from 'react-redux'
import { Modal, Divider, Button } from 'semantic-ui-react';
import { closeModal } from './modalReducer';

export default function ModalWrapper({children, size, header}) {
    const dispatch = useDispatch()

    return(
        <Modal open={true} onCLose={() => dispatch(closeModal())} size={size}>
         {header && <Modal.Header>{header}</Modal.Header>}
         <Modal.Content>
             {children}
         </Modal.Content>
         <Divider />
               <div style={{textAlign: 'center'}}>
                 <Button onClick={() => dispatch(closeModal())} content='Close' />
               </div>
        </Modal>
    )
}