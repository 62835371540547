import { Formik, Form } from 'formik'
import TextInput from '../../../app/common/form/TextInput';
import * as Yup from 'yup'
import { Button } from 'semantic-ui-react';
import { updateUserProfile } from '../../../app/firestore/firestoreService';
import { toast } from 'react-toastify';

export default function ProfileForm({profile}) {

    return (
        <Formik
        initialValues={{
            displayName: profile.displayName
        }}
        validationSchema={Yup.object({
            displayName: Yup.string().required('Display name is required')
        })}
        onSubmit={async (values, {setSubmitting}) => {
            try {
                await updateUserProfile(values)
            }
            catch (error) {
                toast.error(error.message);
            }
            finally {
                setSubmitting(false)
            }
         }}
        >
         {({isSubmitting, isValid, dirty }) => (
         <Form className='ui form'>
         <TextInput name='displayName' placeholder='Display Name'/>
         <Button
         loading={isSubmitting}
         disabled={isSubmitting || !isValid || !dirty}
         floted='right'
         type='submit'
         size='large'
         positive
         content='Update Display Name'
         />
         </Form>
         )}
        </Formik>
    )

}