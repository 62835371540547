import React from "react";
import { Grid } from "semantic-ui-react";
import { MatchStatus } from "../../app/common/constants";

export default function Scorecard({ score, status }) {
  let teamGoals;
  function scorecardColor() {
    switch (status) {
      case MatchStatus.IN_PLAY:
        return "#00ff85";
      case MatchStatus.AWARDED:
      case MatchStatus.FINISHED:
        return "#FFFF00";
      case MatchStatus.PAUSED:
      case MatchStatus.POSTPONED:
      case MatchStatus.SUSPENDED:
      case MatchStatus.CANCELLED:
        return "#dcd9e2";
    }
  }

  switch (status) {
    case MatchStatus.AWARDED:
    case MatchStatus.IN_PLAY:
    case MatchStatus.PAUSED:
    case MatchStatus.FINISHED:
      teamGoals = score;
      break
    case MatchStatus.POSTPONED:
      teamGoals = 'P'
      break
    case MatchStatus.CANCELLED:
      teamGoals = 'C'
      break
    case MatchStatus.SUSPENDED:
      teamGoals = 'S'
      break
  }
 
  return (
    <div
      style={{
        backgroundColor: scorecardColor(),
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Grid.Column style={{ marginTop: 20 }} width={2}>
        <text style={{ fontSize: 30, marginTop: 20 }}>{teamGoals}</text>
      </Grid.Column>
    </div>
  );
}
