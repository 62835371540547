import React, { useState, useEffect } from "react";

import FixtureList from "./FixturesList";
import { useSelector } from "react-redux";
import { getFixtures, getCurrentSeason } from "../../app/common/utils/utils";
import FixturesHeader from "./FixtureHeader";
import { UserStatus } from "../../app/common/constants";
import UserStatusComponent from "../../app/common/components/UserStatusComponent";
import LoadingComponent from "../../app/layout/loadingComponent";

//div with height 80 is a workground to create space between last item and bottom menu on iPhone

export default function FixturesPage() {
  const { currentUserProfile } = useSelector(state => state.profile);

  const [fixtures, setFixtures] = useState(null)
  const [currentSeason, setCurrentSeason] = useState(null)

  useEffect(async () => {
   let currentSeasonDeatils = await getCurrentSeason()
   setCurrentSeason(currentSeasonDeatils)
   let fixtures =  await getFixtures()
   setFixtures(fixtures)
  }, []);

  if(currentUserProfile == null) {
    return(
      <LoadingComponent />
    )
  } else if(currentUserProfile.userStatus !== UserStatus.APPROVED) {
    return(
      <UserStatusComponent status={currentUserProfile.userStatus} />
    )
  }

  return (
    <>
      <FixturesHeader matchweek={currentSeason?.currentMatchweek}/>
      <FixtureList fixtures={fixtures} />

      <div style={{ height: 80 }}></div>
    </>
  );
}