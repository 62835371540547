import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "royspredictions.co.uk",
  databaseURL: "https://royspredictions-bfb08-default-rtdb.firebaseio.com",
  projectId: "royspredictions-bfb08",
  storageBucket: "royspredictions-bfb08.appspot.com",
  messagingSenderId: "276904009532",
  appId: "1:276904009532:web:32cd2f2354f0825efc0839",
  measurementId: "G-YRDRLQJQTD"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

firebase
  .firestore()
  .enablePersistence()
  .catch(err => { console.log(err) });

export default firebase;
