import React, {useEffect} from 'react'
import { useSelector } from "react-redux";
import NavBar from '../../features/nav/NavBar';
import SideNavBar from '../../features/nav/SideNavBar';
import MobileNavBar from '../../features/nav/MobileNavBar';
import HomePage from '../../features/home/HomePage';
import FixturesPage from '../../features/fixtures/FixturesPage';
import PredictionsPage from '../../features/predictions/PredictionsPage';
import LeaderboardPage from '../../features/leaderboard/LeaderboardPage';
import { Container } from 'semantic-ui-react';
import { Route } from 'react-router-dom'
import ModalManager from '../common/modals/ModalManager';
import { ToastContainer } from 'react-toastify';
import AccountPage from '../../features/auth/AccountPage';
import LoadingComponent from './loadingComponent';
import ProfilePage from '../../features/profiles/profilePage/ProfilePage';
import LeagueChatPage from '../../features/chat/LeagueChatPage';
import PrivateRoute from './PrivateRoute';

export default function App() {

  useEffect(() => {
    document.body.style.zoom = "85%"
  }, []);

  const { initialized } = useSelector((state) => state.async)
  
  if (!initialized) return <LoadingComponent />

  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <>
       <ModalManager />
       <ToastContainer position='bottom-right' hideProgressBar />
       <Route exact path='/' component={HomePage}></Route>
       <Route path='/(.+)' render={() => (
         <>
              {isMobile ?
              <MobileNavBar /> :
              <>
              <SideNavBar />
              </>
              }
              <NavBar />
              <Container className='main'>
              <PrivateRoute path='/fixtures' component={FixturesPage} />
              <PrivateRoute exact path='/predictions' component={PredictionsPage} />
              <PrivateRoute path='/leaderboard' component={LeaderboardPage} />
              <PrivateRoute path='/account' component={AccountPage} />
              <PrivateRoute path='/profile/:id' component={ProfilePage}/>
              <PrivateRoute path='/leaguechat' component={LeagueChatPage} />
              </Container>
         </>
       )} />
  
    </>
  );
}
