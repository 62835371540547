import React from "react";
import { useDispatch } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../app/common/form/TextInput";
import { Button, Label } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { sendResetPasswordLink } from "../../app/firestore/firebaseService";
import { useHistory } from "react-router-dom";

export default function ResetPasswordForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ModalWrapper size="small" header="Reset Password">
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required()
            .email()
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await sendResetPasswordLink(values.email);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            if (error.code === "auth/user-not-found") {
              setErrors({ auth: 'User does not exist. Please try a different email.'});
            } else {
              setErrors({ auth: error.message});
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <TextInput name="email" placeholder="Email Address" />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              size="large"
              color="red"
              content="Send reset password link"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
