import React from "react";
import { Segment, Placeholder } from "semantic-ui-react";
export default function PredictionListItemPlaceholder() {
    
  return (
    <Placeholder fluid>
      <Segment.Group>
        <Segment style={{ minHeight: 100 }}>
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        </Segment>
        <Segment style={{minHeight: 100}}>
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Header>
                    </Placeholder>
                </Segment>
       <Segment secondary style={{ minHeight: 50 }} />

      </Segment.Group>
    </Placeholder>
  );
}
