import { MatchStatus } from "../constants";
import { Icon, Label } from "semantic-ui-react";
import { getKickOffTime } from "../utils/utils";

export function renderFixtureStatus(fixture) {
  switch (fixture.status) {
    case MatchStatus.SCHEDULED:
      let date = new Date(fixture.utcDate).toLocaleDateString();
      let time = getKickOffTime(new Date(fixture.utcDate));
      date = date === new Date().toLocaleDateString() ? "Today" : date;
      return (
        <>
          <Icon name="clock" />
          <text style={{ fontSize: 17 }}>{date}</text>
          <text style={{ fontSize: 17, marginLeft: 5 }}>{time}</text>
        </>
      );
    case MatchStatus.IN_PLAY:
      return (
        <>
          <Label circular color="red" empty key="red" />
          <text> Live</text>
        </>
      );
    case MatchStatus.PAUSED:
      return (
        <>
          <Label circular color="grey" empty key="grey" />
          <text> Half Time</text>
        </>
      );
    case MatchStatus.FINISHED:
      return (
        <>
          <Label circular color="yellow" empty key="yellow" />
          <text> Full Time</text>
        </>
      );
    case MatchStatus.POSTPONED:
      return (
        <Label
          as="a"
          style={{ backgroundColor: "#38003c", color: "white", fontSize: 15 }}
          size="medium"
        >
          Postponed
        </Label>
      );
    case MatchStatus.CANCELLED:
      return (
        <Label
          as="a"
          style={{ backgroundColor: "#38003c", color: "white", fontSize: 15 }}
          size="medium"
        >
          Cancelled
        </Label>
      );
    case MatchStatus.SUSPENDED:
      return (
        <Label
          as="a"
          style={{ backgroundColor: "#38003c", color: "white", fontSize: 15 }}
          size="medium"
        >
          Suspended
        </Label>
      );
    case MatchStatus.AWARDED:
      return (
        <Label
          as="a"
          style={{ backgroundColor: "#38003c", color: "white", fontSize: 15 }}
          size="medium"
        >
          Awarded
        </Label>
      );
  }
}
