const LISTEN_TO_LEAGUE_CHAT = "LISTEN_TO_LEAGUE_CHAT";
const CLEAR_COMMENTS = "CLEAR_COMMENTS";

export function listenToLeagueChat(comments) {
  return {
    type: LISTEN_TO_LEAGUE_CHAT,
    payload: comments
  };
}

export function clearLeagueChat() {
  return {
    type: CLEAR_COMMENTS
  };
}

const initialState = {
  comments: []
};

export default function leagueChatReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LISTEN_TO_LEAGUE_CHAT:
      return {
        ...state,
        comments: payload
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comment: []
      };
    default:
      return state;
  }
}
