export const FTECH_CURRENT_SEASON = 'FTECH_CURRENT_SEASON'

const initialSate = {
    currentSeason: null
  };
  
  export default function currentSeasonReducer(state = initialSate, { type, payload }) {
    switch (type) {
      case FTECH_CURRENT_SEASON:
        return {
          ...state,
          currentSeason: payload
        }  
      default:
        return state;
    }
  }