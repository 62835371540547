import { Segment, Header, Button, Label } from "semantic-ui-react";
import * as Yup from "yup";
import TextInput from "../../app/common/form/TextInput";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateUserPassowrd } from "../../app/firestore/firebaseService";

export default function AccountPage() {
  const { currentUser } = useSelector(state => state.auth);
  return (
    <Segment>
      <Header dividing size="large" content="Account" />

      {currentUser.providerId === "password" && (
        <>
          <Header color="teal" sub content="Change Password" />
          <p>Use this form to change your password</p>

          <Formik
            initialValues={{ newPasswordOne: "", newPasswordTwo: "" }}
            validationSchema={Yup.object({
              newPasswordOne: Yup.string().required("Password is required"),
              newPasswordTwo: Yup.string().oneOf(
                [Yup.ref("newPasswordOne"), null],
                "Password do not match"
              )
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await updateUserPassowrd(values);
                setSubmitting(false);
              } catch (error) {
                setErrors({ auth: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, isSubmitting, isValid, dirty }) => (
              <Form className="ui form">
                <TextInput
                  name="newPasswordOne"
                  type="password"
                  placeholder="New Password"
                />
                <TextInput
                  name="newPasswordTwo"
                  type="password"
                  placeholder="Confirm Password"
                />
                {errors.auth && (
                  <Label
                    basic
                    color="red"
                    style={{ marginBottom: 10 }}
                    content={errors.auth}
                  />
                )}
                <Button
                  style={{ display: "block" }}
                  type="submit"
                  disabled={!isValid || isSubmitting || !dirty}
                  loading={isSubmitting}
                  size="large"
                  positive
                  content="Update password"
                />
              </Form>
            )}
          </Formik>
        </>
      )}

      {currentUser.providerId === "facebook" && (
        <>
          <Header color="teal" sub content="" />
          <p>Please visit Facebook to update your account</p>
          <Button
            icon="facebook"
            color="facebook"
            as={Link}
            to="https://facebook.com"
            content="Go to facebook"
          />
        </>
      )}

      {currentUser.providerId === "google.com" && (
        <>
          <Header color="teal" sub content="" />
          <p>Please visit Google to update your account</p>
          <Button
            icon="google"
            color="google plus"
            as={Link}
            to="https://google.com"
            content="Go to google"
          />
        </>
      )}
    </Segment>
  );
}
