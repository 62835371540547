import React, {useEffect, useState} from "react";
import {
  Segment,
  Image,
  Item,
  Header
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import { sumObjValues } from "../../app/common/utils/utils"

export default function PredictionHeader({ matchweek }) {
  const [totalPoints, setTotalPoints] = useState(null);

  const predictionPoints = useSelector(
    state => state.prediction.predictionPoints
  );

  useEffect(() => {
    setTotalPoints(sumObjValues(predictionPoints))
  }, [predictionPoints]);

  return (
      <>
    <Segment.Group style={{ margin: 0}}>
      <Segment basic attached="top" style={{ padding: "0" }}>
        <Image
          src={"/assets/predictionsBackground.jpg"}
          fluid
          style={fixtureImageStyle}
        />

        <Segment basic style={fixtureImageTextStyle}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Header
                  size="huge"
                  content="Predictions"
                  style={{ color: "white" }}
                />
                <p>Matchweek {matchweek}</p>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment>
    </Segment.Group>

{totalPoints !== undefined &&
    <Segment textAlign='center' inverted  style={{ backgroundColor: "#38003c", margin: 0 }}>
      <Header as="h3">
        <Header.Subheader style={{ fontSize: 30, color: '#e90052' }}>{totalPoints}</Header.Subheader>
        Total Points
      </Header>
    </Segment>
    }
    </>
  );
}

const fixtureImageStyle = {
  filter: "brightness(30%)",
  height: '17em'
};

const fixtureImageTextStyle = {
  position: "absolute",
  bottom: "5%",
  left: "5%",
  width: "100%",
  height: "auto",
  color: "white"
};
