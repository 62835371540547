import React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import { UserStatus } from "../constants";
import config from "../../config/appConfig.json"


export default function UserStatusComponent({ status }) {
  
    function displayMessage(status) {
    if (status === UserStatus.PENDING) {
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name="hand paper" />
            Your request is awaiting approval
          </Header>
          <p>
            Welcome to {config.appName}. Your
            request to join the league is being processed. Once approved you will be able to access all
            content. For any enquires contact us at {config.email}.
          </p>
        </Segment>
      );
    }

    if (status === UserStatus.REJECTED) {
        return (
          <Segment placeholder>
            <Header icon>
              <Icon name="ban" />
              Your request was rejected
            </Header>
            <p style={{textAlign: 'center'}}>
              Your request to join the league was rejected. Get in touch with us at {config.email} if you would like us to review this again.
            </p>
          </Segment>
        );
      }
  }

  return (
    displayMessage(status)
  );
}
