import React from "react";
import { Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ProfileContent from "./ProfileContent";
import ProfileHeader from "./ProfileHeader";
import useFirestoreDoc from "../../../app/hooks/useFirestoreDoc";
import { getUserProfile } from "../../../app/firestore/firestoreService";
import { listenToSelectedUserProfile } from "../profileActions";
import LoadingComponent from "../../../app/layout/loadingComponent";
import { UserRoles } from '../../../app/common/constants'

export default function ProfilePage({ match }) {
  const dispatch = useDispatch();
  const { currentUserProfile, selectedUserProfile } = useSelector(state => state.profile);
  const { currentUser } = useSelector(state => state.auth);
  const { loading, error } = useSelector(state => state.async);
  const isAdmin = currentUserProfile.role === UserRoles.ADMIN

  useFirestoreDoc({
    query: () => getUserProfile(match.params.id),
    data: profile => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, match.params.id]
  });

  if ((loading && !selectedUserProfile) || (!selectedUserProfile && !error)) 
     return <LoadingComponent content="Loading profile..." />;

  return (
    <Grid>
      <Grid.Column width={16}>
        <ProfileHeader
          profile={selectedUserProfile}
          isCurrentUser={currentUser.userId === selectedUserProfile.id}
        />
        <ProfileContent
          profile={selectedUserProfile}
          isCurrentUser={currentUser.userId === selectedUserProfile.id}
          isAdmin={isAdmin}
        />
      </Grid.Column>
    </Grid>
  );
}