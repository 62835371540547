import React, { useEffect, useState } from "react";
import { Comment, Segment, Header, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import LeagueChatForm from "./LeagueChatForm";
import {
  getLeagueChatRef,
  firebaseObjectToArray
} from "../../app/firestore/firebaseService";
import { listenToLeagueChat, clearLeagueChat } from "./leagueChatReducer";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { createDataTree } from "../../app/common/utils/utils";
import { UserStatus } from "../../app/common/constants";
import UserStatusComponent from "../../app/common/components/UserStatusComponent";

export default function LeagueChatPage() {
  const dispatch = useDispatch();
  const { comments } = useSelector(state => state.chat);
  const { currentUserProfile } = useSelector(state => state.profile);

  const [showReplyForm, setShowReplyForm] = useState({
    open: false,
    commentId: null
  })

  useEffect(() => {
    getLeagueChatRef().on("value", snapshot => {
      if (!snapshot.exists()) return;
      dispatch(
        listenToLeagueChat(firebaseObjectToArray(snapshot.val()).reverse())
      );
    });

    return () => {
      dispatch(clearLeagueChat());
      getLeagueChatRef().off();
    };
  }, [dispatch]);


  if(currentUserProfile.userStatus !== UserStatus.APPROVED) {
    return(
      <UserStatusComponent status={currentUserProfile.userStatus} />
    )
  }

  function handleCloseReplyForm() {
    setShowReplyForm({ open: false, commentId: null });
  }

  return (
    <>
      <Segment
        textAlign="center"
        attached="top"
        inverted
        color='green'
        style={{ border: "none" }}
      >
        <Header as="h2" icon textAlign="center">
          <Icon name="chat" circular />
          <Header.Content>League Chat</Header.Content>
        </Header>
      </Segment>

      <Segment attached>
        <LeagueChatForm parentId={0} closeForm={handleCloseReplyForm} />

        <Comment.Group>
          {createDataTree(comments)
          .map(comment => (
              <Comment key={comment.id}>
                <Comment.Avatar
                  src={comment.photoURL || "/assets/profile-image.jpg"}
                />
                <Comment.Content>
                  <Comment.Author as={Link} to={`/profile/${comment.userId}`}>
                    {comment.displayName}
                  </Comment.Author>
                  <Comment.Metadata>
                    <div>{formatDistance(comment.date, new Date())}</div>
                  </Comment.Metadata>
                  <Comment.Text>
                    {comment.text.split("\n").map((text, i) => (
                      <span key={i}>
                        {text}
                        <br />
                      </span>
                    ))}
                  </Comment.Text>
                  <Comment.Actions>
                    <Comment.Action
                      onClick={() =>
                        setShowReplyForm({ open: true, commentId: comment.id })
                      }
                    >
                      Reply
                    </Comment.Action>
                    {showReplyForm.open &&
                      showReplyForm.commentId === comment.id && (
                        <LeagueChatForm
                          parentId={comment.id}
                          closeForm={handleCloseReplyForm}
                        />
                      )}
                  </Comment.Actions>
                </Comment.Content>
                {comment.childNodes.length > 0 && (
                  <Comment.Group>
                    {comment.childNodes.reverse().map(child => (
                      <Comment key={child.id}>
                        <Comment.Avatar
                          src={child.photoURL || "/assets/profile-image.jpg"}
                        />
                        <Comment.Content>
                          <Comment.Author
                            as={Link}
                            to={`/profile/${child.userId}`}
                          >
                            {child.displayName}
                          </Comment.Author>
                          <Comment.Metadata>
                            <div>
                              {formatDistance(child.date, new Date())}
                            </div>
                          </Comment.Metadata>
                          <Comment.Text>
                            {child.text.split("\n").map((text, i) => (
                              <span key={i}>
                                {text}
                                <br />
                              </span>
                            ))}
                          </Comment.Text>
                          <Comment.Actions>
                            <Comment.Action
                              onClick={() =>
                                setShowReplyForm({
                                  open: true,
                                  commentId: child.id
                                })
                              }
                            >
                              Reply
                            </Comment.Action>
                            {showReplyForm.open &&
                              showReplyForm.commentId === child.id && (
                                <LeagueChatForm
                                  parentId={child.parentId}
                                  closeForm={handleCloseReplyForm}
                                />
                              )}
                          </Comment.Actions>
                        </Comment.Content>
                      </Comment>
                    ))}
                  </Comment.Group>
                )}
              </Comment>
            ))
          }
        </Comment.Group>
      </Segment>
      <div style={{ height: 80 }}></div>
    </>
  );
}
