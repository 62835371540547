import firebase from "../config/firebase";
import { setUserProfileData } from "./firestoreService";
import { toast } from "react-toastify";

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map(e =>
      Object.assign({}, e[1], { id: e[0] })
    );
  }
}

export function signInWithEmail(creds) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password);
}

export function signOutFirebase() {
  return firebase.auth().signOut();
}

export async function registerInFirebase(creds) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(creds.email, creds.password);
    await result.user.updateProfile({
      displayName: creds.displayName
    });
    return await setUserProfileData(result.user);
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider) {
  let provider;
  switch (selectedProvider) {
    case "facebook":
      provider = new firebase.auth.FacebookAuthProvider();
      break;
    case "google":
      provider = new firebase.auth.GoogleAuthProvider();
      break;
  }

  try {
    const result = await firebase.auth().signInWithPopup(provider);
    if (result.additionalUserInfo.isNewUser) {
      await setUserProfileData(result.user);
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export function updateUserPassowrd(creds) {
  const user = firebase.auth().currentUser;
  return user.updatePassword(creds.newPasswordOne);
}

export function uploadToFirebaseStorage(file, filename) {
  const user = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref();
  return storageRef.child(`${user.uid}/user_image/${filename}`).put(file);
}

export function deleteFromFirebaseStorage(filename) {
  const userId = firebase.auth().currentUser.uid;
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(`${userId}/user_image/${filename}`);
  return photoRef.delete();
}

export function addChatComment(values) {
  const user = firebase.auth().currentUser;
  const newComment = {
    displayName: user.displayName,
    photoURL: user.photoURL,
    userId: user.uid,
    text: values.comment,
    date: Date.now(),
    parentId: values.parentId
  };
  return firebase
    .database()
    .ref("chat/league")
    .push(newComment);
}

export function getLeagueChatRef() {
  return firebase
    .database()
    .ref("chat/league")
    .orderByKey();
}

export function getPredictionPoints(userId, currentSeason) {
  let season = currentSeason.season;
  let matchweek = currentSeason.currentMatchweek;
  return firebase
    .database()
    .ref(
      `userPredictionPoints/PL/${season}/matchweek${matchweek}/fixturePoints/${userId}`
    );
}

export function getMatchweekTotalPointsRef(currentSeason) {
  let season = currentSeason.season;
  let matchweek = currentSeason.currentMatchweek;
  return firebase
    .database()
    .ref(
      `userPredictionPoints/PL/${season}/matchweek${matchweek}/matchweekTotalPoints`
    );
}

export function getPreviousMatchweekPoints(userId, currentSeason) {
  let season = currentSeason.season;
  let matchweek = currentSeason.currentMatchweek - 1;
  let ref = firebase
    .database()
    .ref(
      `userPredictionPoints/PL/${season}/matchweek${matchweek}/matchweekTotalPoints/${userId}`
    );

  return ref.once("value").then(data => {
    if (data.val()) {
      return data.val().points;
    }
  });
}

export async function sendResetPasswordLink(email) {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    toast.success(`Password reset link has been sent to ${email}`);
  } catch (error) {
    throw error;
  }
}

export async function getLeaderboardUpdateMatchweek() {
  const ref = firebase.database().ref("leaderboardDetails/PL/updatedMatchweek");

  return await ref.once("value").then(updatedMatchday => {
    return updatedMatchday.val();
  });
}
