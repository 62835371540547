import React, {useState, useEffect} from "react";
import { Tab, Menu, Label, Header } from "semantic-ui-react";
import MyDetailsTab from "./MyDetailsTab";
import PhotosTab from "./PhotosTab";
import { getRequests } from "../../../app/firestore/firestoreService";
import RequestList from "../../admin/RequestList";

export default function ProfileContent({ profile, isCurrentUser, isAdmin }) {

const [requests, setRequests] = useState({requests: null, count: 0}) 

useEffect(async () => {
  if(isAdmin) {
    let userRequests = await getRequests()
    if(userRequests) {
      setRequests({requests: userRequests, count: userRequests.length})
    }
  }
}, []);

function removeFromList(request) {
  let newRequestList = requests.requests.filter(req => req.id !== request.id)
  setRequests({requests: newRequestList, count: newRequestList.length})
}

  const adminPanes = [
    {
      menuItem: (
        <Menu.Item key="Pending">
          Pending<Label color='red'>{requests.count}</Label>
        </Menu.Item>
      ),
      render: () => <RequestList requests={requests} removeFromList={removeFromList} />
    },
    {
      menuItem: (
        <Menu.Item key="Approved">
          Approved
        </Menu.Item>
      ),
      render: () => <Tab.Pane>Approved Users</Tab.Pane>
    },
    {
      menuItem: "Rejected",
      render: () => <Tab.Pane>Rejected Users</Tab.Pane>
    }
  ];

  const panes = [
    {
      menuItem: "My Details",
      render: () => (
        <MyDetailsTab profile={profile} isCurrentUser={isCurrentUser} />
      )
    },
    {
      menuItem: "Photos",
      render: () => (
        <PhotosTab profile={profile} isCurrentUser={isCurrentUser}/>
      )
    }
  ];

  return (
    <>
      <Tab
        menu={{
          color: "violet",
          inverted: true,
          attached: true,
          tabular: false
        }}
        panes={panes}
      />
      {isAdmin && (
        <>
        <Header
        floated="left"
        content='League Players'
        style={{marginTop: 20}}
      />
        <Tab
          menu={{
            color: "violet",
            inverted: true,
            attached: true,
            tabular: false
          }}
          panes={adminPanes}
        />
        <div style={{ height: 80 }}></div>
        </>
      )}
    </>
  );
}
