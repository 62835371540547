import { FETCH_LEADERBOARD, SET_USER_TOTAL_POINTS, SET_LEADERBOARD_UPDATED_MACTHWEEK } from "./leaderboardActions";

const initialSate = {
  leaderboard: null,
  currentUserTotalPoints: "-",
  leaderbaordUpdatedMatchweek: null
};

export default function leaderboardReducer(state = initialSate, { type, payload }) {
  switch (type) {
    case FETCH_LEADERBOARD:
      return {
        ...state,
        leaderboard: payload
      }
    case SET_USER_TOTAL_POINTS:
      return {
        ...state,
        currentUserTotalPoints: payload
      }
      case SET_LEADERBOARD_UPDATED_MACTHWEEK:
      return {
        ...state,
        leaderbaordUpdatedMatchweek: payload
      }         
    default:
      return state;
  }
}
