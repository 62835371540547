import {
  SET_PREDICTION,
  UPDATE_PREDICTION,
  DELETE_PREDICTION,
  FETCH_PREDICTIONS,
  FETCH_PREDICTION_POINTS
} from "./predictionConstants";

const initialSate = {
  predictions: [],
  predictionPoints: null
};

export default function predictionReducer(state = initialSate, { type, payload })
 {
  switch (type) {
    case SET_PREDICTION:
      return {
        ...state,
        predictions: [...state.predictions, payload]
      };
    case UPDATE_PREDICTION:
      return {
        ...state,
        predictions: [
          ...state.predictions.filter(
            pre => pre.fixtureId !== payload.fixtureId
          ),
          payload
        ]
      };
    case DELETE_PREDICTION:
      return {
        ...state,
        predictions: [
          ...state.predictions.filter(
            pre => pre.fixtureId !== payload.fixtureId
          )
        ]
      };
    case FETCH_PREDICTIONS:
      return {
        ...state,
        predictions: [...payload]
      }
    case FETCH_PREDICTION_POINTS:
      return {
        ...state,
        predictionPoints: payload
      }     
    default:
      return state;
  }
}