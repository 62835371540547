import React from "react";
import { addChatComment } from "../../app/firestore/firebaseService";
import { Form, Formik } from "formik";
import TextArea from "../../app/common/form/TextArea";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import * as Yup from 'yup'

export default function LeagueChatForm({parentId, closeForm}) {
  return (
    <Formik
      initialValues={{comment: ''}}
      validationSchema={Yup.object({
        comment: Yup.string().required('Please enter a comment')
      })}
      onSubmit={async (values, { setSubmitting, resetForm}) => {
        try {
          await addChatComment({...values, parentId});
          resetForm();
        } catch (error) {
          toast.error(error.message);
        } finally {
          setSubmitting(false);
          closeForm()
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
      <Form style={{marginBottom: 50}} className='ui form'>
        <TextArea name='comment' placeholder='Please enter your comment here' rows={2} />
        {!parentId ?
        <Button type="submit" floated='right' disabled={!isValid} loading={isSubmitting} content='Send' icon='edit' primary/>
        :
        <Button type="submit" floated='right' disabled={!isValid} loading={isSubmitting} content='Reply' icon='edit' secondary/>
        }
      </Form>
      )}
    </Formik>
  );
}
