import React from "react";
import RequestListItem from "./RequestListItem";
import { Grid, Segment } from "semantic-ui-react";

export default function RequestList({ requests, removeFromList }) {
   
  if (requests.count === 0) {
    return (
      <Segment>
        No pending requests
      </Segment>
    )
  }
  
  return (
    <Grid>
      <Grid.Column width={16}>
        {requests.requests.map(request => (
          <RequestListItem request={request} key={request.id} removeFromList={removeFromList} />
        ))}
      </Grid.Column>
    </Grid>
  );
}