import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Icon, Menu } from "semantic-ui-react";

export default function SideNavBar() {
  const [activeItem, setActiveItem] = useState("");
  
  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  return (
    <Menu color="violet" inverted fixed="left" vertical icon="labeled">
      <Menu.Item
        as={Link}
        to="/"
        name="home"
        position="center"
        active={activeItem === "gamepad"}
        onClick={handleItemClick}
      >
        <Icon size="mini" name="home" />
        Home
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/fixtures"
        name="fixtures"
        position="center"
        color="red"
        active={activeItem === "fixtures"}
        onClick={handleItemClick}
      >
        <Icon name="soccer" />
        Fixtures
      </Menu.Item>

      <Menu.Item
        position="center"
        as={Link}
        to="/predictions"
        name="predictions"
        active={activeItem === "predictions"}
        onClick={handleItemClick}
      >
        <Icon name="chart line" />
        Predictions
      </Menu.Item>

      <Menu.Item
        position="center"
        as={Link}
        to="/leaderboard"
        name="leaderboard"
        active={activeItem === "leaderboard"}
        color="red"
        onClick={handleItemClick}
      >
        <Icon name="table" />
        Leaderboard
      </Menu.Item>
      <Menu.Item
        position="center"
        as={Link}
        to="/leagueChat"
        name="chat"
        active={activeItem === "chat"}
        onClick={handleItemClick}
      >
        <Icon name="chat" />
        LeagueChat
      </Menu.Item>
    </Menu>
  );
}
