import React from "react";
import PredictionListItem from "./PredictionListItem";
import PredictionListItemPlaceholder from "../../app/common/PredictionListItemPlaceholder";

export default function PredictionsList({fixtures, userId, currentSeason}) {

if (!fixtures)
    return (
      <>
        <PredictionListItemPlaceholder />
        <PredictionListItemPlaceholder />
      </>
    );

  return (
    <>
      {fixtures.map(fixture => {
        return (
          <PredictionListItem
            fixture={fixture}
            userId={userId}
            key={fixture.id}
            currentSeason={currentSeason}
          />
        );
      })}
    </>
  );
}

