export const FETCH_LEADERBOARD = 'FETCH_LEADERBOARD'
export const SET_USER_TOTAL_POINTS = 'SET_USER_TOTAL_POINTS'
export const SET_LEADERBOARD_UPDATED_MACTHWEEK = 'SET_LEADERBOARD_UPDATED_MACTHWEEK'



export function listenToLeaderboard(leaderboard) {
  return {
    type: FETCH_LEADERBOARD,
    payload: leaderboard
  }
}

export function setUserTotalPoints(points) {
  return {
    type: SET_USER_TOTAL_POINTS,
    payload: points
  }
}

export function setLeaderbaordUpdatedMatchweek(matchweek) {
  return {
    type: SET_LEADERBOARD_UPDATED_MACTHWEEK,
    payload: matchweek
  }
}