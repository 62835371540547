import firebase from "../config/firebase";
import { UserStatus } from "../common/constants";

const db = firebase.firestore();

export function getDataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id
  };
}

export function listenToUserPredictionsFromFirestore(userId, season, matchweek) {
  let docId = `${season}_matchweek${matchweek}`
  return db
    .collection("users")
    .doc(userId)
    .collection("predictions")
    .doc(docId);
}

export async function getAllPredictionsFromFirestore(userId) {
  let results = await db
    .collection("users")
    .doc(userId)
    .collection("predictions")
    .doc("season_matchweek")
    .get();

  if (results.data()) {
    return results.data().predictions;
  } else {
    return [];
  }
}

export function addUserPredictionToFirestore(prediction, userId, currentSeason) {
  let docId = `${currentSeason.season}_matchweek${currentSeason.currentMatchweek}`
  db.collection("users")
    .doc(userId)
    .collection("predictions")
    .doc(docId)
    .set(
      {
        predictions: firebase.firestore.FieldValue.arrayUnion({
          fixtureId: prediction.fixtureId,
          predicatedHomeTeamScore: prediction.predicatedHomeTeamScore,
          predicatedAwayTeamScore: prediction.predicatedAwayTeamScore
        })
      },
      { merge: true }
    );
}

export function setupUserPredictionsInFirestore(userId, season, matchweek) {
    let docId = `${season}_matchweek${matchweek}`

  db.collection("users")
    .doc(userId)
    .collection("predictions")
    .doc(docId)
    .set(
      {
        matchweek: matchweek,
        season: season.split('Season')[1],
        userId: userId
      },
      { merge: true }
    );
}

export function updateUserPredictionToFirestore(oldValues, newValues, userId, currentSeason) {
  let docId = `${currentSeason.season}_matchweek${currentSeason.currentMatchweek}`
  let ref = db
    .collection("users")
    .doc(userId)
    .collection("predictions")
    .doc(docId);

  ref.update({
    predictions: firebase.firestore.FieldValue.arrayRemove({
      fixtureId: oldValues.fixtureId,
      predicatedHomeTeamScore: oldValues.predicatedHomeTeamScore,
      predicatedAwayTeamScore: oldValues.predicatedAwayTeamScore
    })
  });

  ref.update({
    predictions: firebase.firestore.FieldValue.arrayUnion({
      fixtureId: newValues.fixtureId,
      predicatedHomeTeamScore: newValues.predicatedHomeTeamScore,
      predicatedAwayTeamScore: newValues.predicatedAwayTeamScore
    })
  });
}

export function setUserProfileData(user) {
  return db
    .collection("users")
    .doc(user.uid)
    .set({
      displayName: user.displayName,
      email: user.email,
      userStatus: UserStatus.PENDING,
      photoURL: user.photoURL || null,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    });
}

export function getUserProfile(userId) {
  return db.collection("users").doc(userId);
}

export async function updateUserProfile(profile) {
  const user = firebase.auth().currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName
      });
    }
    return await db
      .collection("users")
      .doc(user.uid)
      .update(profile);
  } catch (error) {
    throw error;
  }
}

export async function updateUserProfilePhoto(downloadURL, filename) {
  const user = firebase.auth().currentUser;
  const userDocRef = db.collection("users").doc(user.uid);

  try {
    const userDoc = await userDocRef.get();
    if (!userDoc.data.photoURL) {
      await db
        .collection("users")
        .doc(user.uid)
        .update({
          photoURL: downloadURL
        });
      await user.updateProfile({
        photoURL: downloadURL
      });
    }
    return await db
      .collection("users")
      .doc(user.uid)
      .collection("photos")
      .add({
        name: filename,
        url: downloadURL
      });
  } catch (error) {
    throw error;
  }
}

export function getUserPhotos(userId) {
  return db
    .collection("users")
    .doc(userId)
    .collection("photos");
}

export async function setMainPhoto(photo) {
  const user = firebase.auth().currentUser;
  try {
    await db
      .collection("users")
      .doc(user.uid)
      .update({
        photoURL: photo.url
      });
    return await user.updateProfile({
      photoURL: photo.url
    });
  } catch (error) {
    throw error;
  }
}

export function deletePhotoFromCollection(photoId) {
    const userId = firebase.auth().currentUser.uid;
    return db.collection('users').doc(userId).collection('photos').doc(photoId).delete();
}

export async function getRequests() {
   let snapshot = await db.collection('requests').get();
   return snapshot.docs.map(doc => getDataFromSnapshot(doc))
}

export async function approveRejectUser(status, request) {
  let ref = db.collection('requests').doc(request.id);
  await ref.update({ userStatus: status });
}

export function getLeaderboard(season) {
  return db
    .collection("leaderboard")
    .doc(season)
    .get()
}

export function getUserLeaderboardPoints(season) {
  return db
    .collection("leaderboard")
    .doc(season)
    .get()
}