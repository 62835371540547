import React, { useState, useEffect } from "react";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { TeamLogos, MatchStatus } from "../../app/common/constants";
import { useSelector, useDispatch } from "react-redux";
import { updatePrediction } from "./predictionActions";
import {
  updateUserPredictionToFirestore,
  addUserPredictionToFirestore
} from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { renderFixtureStatus } from "../../app/common/components/fixtures";
import { convertDateToUTC } from "../../app/common/utils/utils";

export default function PredictionListItem({ fixture, userId, currentSeason }) {
  const dispatch = useDispatch();

  const prediction = useSelector(state =>
    state.prediction.predictions.find(pre => pre.fixtureId === fixture.id)
  );
  const predictionPoints = useSelector(
    state => state.prediction.predictionPoints
  );

  const inititalValues = prediction ?? {
    fixtureId: fixture.id,
    predicatedHomeTeamScore: "",
    predicatedAwayTeamScore: ""
  };

  const [scoreUpdated, setScoreUpdated] = useState(false);
  const [scoreSubmissionLoader, setScoreSubmissionLoader] = useState(false);
  const [values, setValues] = useState(inititalValues);
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    if (scoreUpdated) {
      handleUpdateOrCreatePredictions();
      setScoreUpdated(false);
      setScoreSubmissionLoader(false);
    }
  }, [scoreUpdated]);

  function isSubmittable() {
    return convertDateToUTC(new Date(fixture.utcDate)) > convertDateToUTC(new Date()) && fixture.status === MatchStatus.SCHEDULED
  }

  function handleUpdateOrCreatePredictions() {
    try {
      prediction
        ? updateUserPredictionToFirestore(
            prediction,
            values,
            userId,
            currentSeason
          )
        : addUserPredictionToFirestore(values, userId, currentSeason);

      dispatch(updatePrediction({ ...values }));
    } catch (error) {
      toast.error("There was a problem saving the score");
      toast.error(error.message);
    }
  }

  const updatePredictionsState = (score, homeOrAwayTeamScore) => {
    let teamScore = score.target.value;

    if (isSubmittable()) {
      if (/^\d+$/.test(teamScore) || teamScore === "") {
        setValues(prevState => ({
          ...prevState,
          [homeOrAwayTeamScore]: teamScore
        }));
        // setDisableInput(true);
        setScoreSubmissionLoader(true);
        setScoreUpdated(true);
      } else {
        toast.error("Enter numeric characters only");
      }
    } else {
      toast.error("This game is locked!");
    }
  };

  function renderFixture(teamName, team) {
    let homeOrAwayTeamScore =
      team === "homeTeam"
        ? "predicatedHomeTeamScore"
        : "predicatedAwayTeamScore";

    let teamScore = values[homeOrAwayTeamScore];

    return (
      <Segment style={{ backgroundColor: "#e90052" }} attached>
        <Grid verticalAlign="middle">
          <div
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Grid.Column width={1}>
              <img
                style={{
                  marginTop: 25,
                  height: "2.7rem",
                  width: "2.7rem"
                }}
                src={TeamLogos[teamName]}
              />
            </Grid.Column>
          </div>
          <Grid.Column width={9}>
            <text style={{ fontSize: 20, color: "white", alignText: "center" }}>
              {teamName}
            </text>
          </Grid.Column>
          <Grid.Column width={1}>
            <input
              className="goals-input-field"
              type="tel"
              min="1"
              max="9"
              maxLength='1'
              value={teamScore}
              onChange={value =>
                updatePredictionsState(value, homeOrAwayTeamScore)
              }
              disabled={disableInput}
              style={{
                width: 55,
                height: 60,
                backgroundColor: "#04f5ff",
                textAlign: "center"
              }}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  function renderSubmissionIcon() {
    return (
      <Icon.Group size="big">
        <Icon color="green" name="soccer" />
        <Icon color="green" corner name="check" />
      </Icon.Group>
    );
  }

  function renderSubmissionLoadingIcon() {
    return (
      <Icon.Group size="big">
        <Icon loading inverted color="green" name="circle notch" />
      </Icon.Group>
    );
  }

  function displayScoreSubmissionIcon() {
    if (prediction) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {prediction.predicatedHomeTeamScore && renderSubmissionIcon()}
          {prediction.predicatedAwayTeamScore && renderSubmissionIcon()}
          {scoreSubmissionLoader && renderSubmissionLoadingIcon()}
        </div>
      );
    }
  }

  function renderPoints(fixtureStatus) {
    if (
      fixtureStatus === MatchStatus.IN_PLAY ||
      fixtureStatus === MatchStatus.PAUSED ||
      fixtureStatus === MatchStatus.FINISHED ||
      fixtureStatus === MatchStatus.AWARDED
    ) {
      let points = predictionPoints && predictionPoints[fixture.id] != "undefined"
      ? predictionPoints[fixture.id]
      : 0;
      return (
        <Grid.Column
          style={{ backgroundColor: "#38003c" }}
          width={7}
          textAlign="center"
        >
          <text style={{ fontSize: 20, color: "white" }}>
            Points: {points ? points : 0}
          </text>
        </Grid.Column>
      );
    }
  }

  function renderFixtureDeatils(fixture) {
    return (
      <Segment>
        <Grid verticalAlign="middle">
          <Grid.Column width={5}>{renderFixtureStatus(fixture)}</Grid.Column>
          {renderPoints(fixture.status)}
          <Grid.Column floated="right" width={2}>
            {displayScoreSubmissionIcon()}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  let homeTeamName = fixture.homeTeam.name.replace(" FC", "");
  let awayTeamName = fixture.awayTeam.name.replace(" FC", "");

  return (
    <>
      <Segment.Group>
        {renderFixture(homeTeamName, "homeTeam")}
        {renderFixture(awayTeamName, "awayTeam")}
        {renderFixtureDeatils(fixture)}
      </Segment.Group>
    </>
  );
}