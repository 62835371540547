import React, { useEffect, useState } from "react";
import { Table, Label, Segment, Header, Statistic } from "semantic-ui-react";
import { getLeaderboard } from "../../app/firestore/firestoreService";
import {
  listenToLeaderboard,
  setUserTotalPoints,
  setLeaderbaordUpdatedMatchweek
} from "./leaderboardActions";
import { useSelector, useDispatch } from "react-redux";
import { UserStatus } from "../../app/common/constants";
import LoadingComponent from "../../app/layout/loadingComponent";
import UserStatusComponent from "../../app/common/components/UserStatusComponent";
import {
  sortLeaderboard,
  getCurrentSeason
} from "../../app/common/utils/utils";
import {
  getMatchweekTotalPointsRef,
  getPreviousMatchweekPoints,
  getLeaderboardUpdateMatchweek
} from "../../app/firestore/firebaseService";

export default function LeaderboardPage() {
  const dispatch = useDispatch();
  const {
    leaderboard,
    currentUserTotalPoints,
    leaderbaordUpdatedMatchweek
  } = useSelector(state => state.leaderboard);
  const { currentUserProfile } = useSelector(state => state.profile);

  const [matchweekPoints, setMatchweekPoints] = useState(null);
  const [currentSeason, setCurrentSeason] = useState(null);
  const [sortedLeaderboard, setSortedLeaderboard] = useState(null);

  const [lastMatchweekPoints, setLastMatchweekPoints] = useState("-");

  useEffect(async () => {
    let currentSeasonDeatils = await getCurrentSeason();
    setCurrentSeason(currentSeasonDeatils);
    let leaderboard = await getLeaderboard(currentSeasonDeatils?.season);
    let leaderboardUnsorted;
    if (leaderboard.data()) {
      leaderboardUnsorted = Object.values(leaderboard.data());
      let leaderboardUpdatedWeek = await getLeaderboardUpdateMatchweek();
      dispatch(listenToLeaderboard(leaderboardUnsorted));
      dispatch(setLeaderbaordUpdatedMatchweek(leaderboardUpdatedWeek));
      let userTotalPoints = leaderboard.data()[currentUserProfile.id];
      if (userTotalPoints) {
        dispatch(setUserTotalPoints(userTotalPoints.points));
      }
    }

    getPreviousMatchweekPoints(
      currentUserProfile.id,
      currentSeasonDeatils
    ).then(points => {
      setLastMatchweekPoints(points);
    });

    const matchweekTotalPointsRef = getMatchweekTotalPointsRef(
      currentSeasonDeatils
    );
    await matchweekTotalPointsRef.on("value", async snapshot => {
      if (!snapshot.exists()) return;
      setMatchweekPoints(snapshot.val());
    });

    return () => {
      matchweekTotalPointsRef.off();
    };
  }, []);

  useEffect(async () => {
    if (leaderboard && currentSeason) {
      setLeaderboardView(leaderboard, matchweekPoints, currentSeason);
    }
    updateUserTotal();
  }, [matchweekPoints, leaderboard]);

  function updateUserTotal() {
    if (matchweekPoints && matchweekPoints[currentUserProfile.id] && currentSeason.currentMatchweek != leaderbaordUpdatedMatchweek) {
      dispatch(
        setUserTotalPoints(
          currentUserTotalPoints + matchweekPoints[currentUserProfile.id].points
        )
      );
    }
  }

  function setLeaderboardView(
    leaderboard,
    matchweekPoints,
    currentSeasonDeatils
  ) {
    let leaderboardSorted = sortLeaderboard(
      leaderboard,
      matchweekPoints,
      currentSeasonDeatils,
      leaderbaordUpdatedMatchweek
    );
    setSortedLeaderboard(leaderboardSorted);
  }

  if (currentUserProfile == null && leaderboard == null) {
    return <LoadingComponent />;
  } else if (currentUserProfile.userStatus !== UserStatus.APPROVED) {
    return <UserStatusComponent status={currentUserProfile.userStatus} />;
  }

  function displayUsername(user, index) {
    if (index === 0) {
      return (
        <Label style={{ backgroundColor: "#FFD700" }} image>
          <img src={user?.photoURL || "/assets/profile-image.jpg"} />
          {user.displayName}
        </Label>
      );
    } else {
      return (
        <Label image>
          <img src={user?.photoURL || "/assets/profile-image.jpg"} />
          {user.displayName}
        </Label>
      );
    }
  }

  return (
    <>
      <Segment
        vertical
        size="massive"
        textAlign="center"
        style={{ backgroundColor: "#38003c" }}
        inverted
      >
        Leaderboard {currentSeason?.season.split("Season")[1]}
      </Segment>
      <Segment inverted vertical style={{ backgroundColor: "#38003c" }}>
        <Statistic.Group widths="2">
          <Statistic inverted color="green">
            <Statistic.Value>
              {lastMatchweekPoints ? lastMatchweekPoints : "-"}
            </Statistic.Value>
            <Statistic.Label>Last Matchweek Points</Statistic.Label>
          </Statistic>
          <Statistic inverted color="green">
            <Statistic.Value>
              {currentUserTotalPoints ? currentUserTotalPoints : 0}
            </Statistic.Value>
            <Statistic.Label>Total Points</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>

      <Table
        fixed
        unstackable
        style={{ backgroundColor: "#38003c" }}
        inverted
        striped
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">Pos</Table.HeaderCell>
            <Table.HeaderCell width="8">Player</Table.HeaderCell>
            <Table.HeaderCell>Pts</Table.HeaderCell>
            <Table.HeaderCell>
              Week {currentSeason?.currentMatchweek}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedLeaderboard &&
            sortedLeaderboard.map((user, index) => {
              // let userMatchweekPoints = getUserMatchweekTotalPoints(user);
              let position = index + 1;
              return (
                <Table.Row>
                  <Table.Cell>
                    <Label circular size="large">
                      {position}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>{displayUsername(user, index)}</Table.Cell>
                  <Table.Cell style={{ fontWeight: "bold" }}>
                    {user?.points || 0}
                  </Table.Cell>
                  <Table.Cell style={{ color: "#00ff85", fontWeight: "bold" }}>
                    {user?.plusPoints
                      ? `+ ${user?.plusPoints}`
                      : user?.plusPoints}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <div style={{ height: 80 }}></div>
    </>
  );
}
