import React from "react";
import {useDispatch} from 'react-redux'
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../app/common/form/TextInput";
import { Button, Label, Divider } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";
import { useHistory } from "react-router-dom";


export default function LoginForm() {
     const dispatch = useDispatch();
     const history = useHistory();

  return (
    <ModalWrapper size="small" header="Login">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required()
        })}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          try {
            await signInWithEmail(values)
            setSubmitting(false);
            dispatch(closeModal())
            history.push("/fixtures");
            window.location.reload();
          }
          catch (error) {
            setErrors({auth: 'Incorrect email or password'})
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className="ui form">
            <TextInput name="email" placeholder="Email Address" />
            <TextInput name="password" placeholder="Password" type="password" />
            {errors.auth && <Label basic color='red' style={{marginBottom: 10}} content={errors.auth}/>}
            <Button loading={isSubmitting} 
            disabled={!isValid || !dirty || isSubmitting}
            type='submit'
            fluid
            size='large'
            color='blue'
            content='Login'
            />
            <Divider horizontal>Or</Divider>
            <SocialLogin />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
