export const MatchStatus = {
    SCHEDULED: 'SCHEDULED',
    IN_PLAY: 'IN_PLAY',
    PAUSED: 'PAUSED',
    CANCELLED: 'CANCELLED',
    POSTPONED: 'POSTPONED',
    SUSPENDED: 'SUSPENDED',
    AWARDED: 'AWARDED',
    FINISHED: 'FINISHED',
    NOT_IN_PLAY: 'NOT_IN_PLAY'
};

export const UserStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED'
};

export const UserRoles = {
    ADMIN: 'ADMIN',
    PLAYER: 'PLAYER'
}

export const TeamLogos = {
    'Arsenal': '/assets/teamLogos/Arsenal.png',
    'Aston Villa': '/assets/teamLogos/Aston_Villa.png',
    'Barnsley': '/assets/teamLogos/Barnsley.png',
    'AFC Bournemouth': '/assets/teamLogos/Bournemouth.png',
    'Birmingham City': '/assets/teamLogos/Birmingham_City.png',
    'Blackburn Rovers': '/assets/teamLogos/Blackburn_Rovers.png',
    'Bolton Wanderers': '/assets/teamLogos/Bolton_Wanderers.png',
    'Brentford': '/assets/teamLogos/Brentford.png',
    'Brighton & Hove Albion': '/assets/teamLogos/Brighton.png',
    'Bristol City': '/assets/teamLogos/Bristol_City.png',
    'Burnley': '/assets/teamLogos/Burnley.png',
    'Cardiff City': '/assets/teamLogos/Cardiff_City.png',
    'Chelsea': '/assets/teamLogos/Chelsea.png',
    'Coventry City': '/assets/teamLogos/Coventry_City.png',
    'Crystal Palace': '/assets/teamLogos/Crystal_Palace.png',
    'Derby County': '/assets/teamLogos/Derby_County.png',
    'Everton': '/assets/teamLogos/Everton.png',
    'Fulham': '/assets/teamLogos/Fulham.png',
    'Huddersfield Town AFC': '/assets/teamLogos/Huddersfield_Town.png',
    'Hull City AFC': '/assets/teamLogos/Hull_City.png',
    'Ipswich Town': '/assets/teamLogos/Ipswich_Town.png',
    'Leeds United': '/assets/teamLogos/Leeds_United.png',
    'Leicester City': '/assets/teamLogos/Leicester_City.png',
    'Liverpool': '/assets/teamLogos/Liverpool.png',
    'Luton Town': '/assets/teamLogos/Luton_Town.png',
    'Manchester City': '/assets/teamLogos/Manchester_City.png',
    'Manchester United': '/assets/teamLogos/Manchester_United.png',
    'Middlesbrough': '/assets/teamLogos/Middlesbrough.png',
    'Millwall': '/assets/teamLogos/Millwall.png',
    'Newcastle United': '/assets/teamLogos/Newcastle.png',
    'Norwich City': '/assets/teamLogos/Norwich_City.png',
    'Nottingham Forest': '/assets/teamLogos/Nottingham_Forest.png',
    'Preston North End': '/assets/teamLogos/Preston_North_End.png',
    'Queens Park Rangers': '/assets/teamLogos/Queens_Park_Rangers.png',
    'Reading': '/assets/teamLogos/Reading.png',
    'Rotherham United': '/assets/teamLogos/Rotherham_United.png',
    'Sheffield United': '/assets/teamLogos/Sheffield_United.png',
    'Sheffield Wednesday': '/assets/teamLogos/Sheffield_Wednesday.png',
    'Southampton': '/assets/teamLogos/Southampton.png',
    'Stoke City': '/assets/teamLogos/Stoke_City.png',
    'Swansea City AFC': '/assets/teamLogos/Swansea_City.png',
    'Tottenham Hotspur': '/assets/teamLogos/Tottenham.png',
    'Watford': '/assets/teamLogos/Watford.png',
    'West Bromwich Albion': '/assets/teamLogos/West_Bromwich_Albion.png',
    'West Ham United': '/assets/teamLogos/West_Ham.png',
    'Wigan Athletic': '/assets/teamLogos/Wigan_Athletic.png',
    'Wolverhampton Wanderers': '/assets/teamLogos/Wolves.png',
    'Wycombe Wanderers': '/assets/teamLogos/Wycombe_Wanderers.png',
};