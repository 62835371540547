import React from 'react'
import FixtureListItem from './FixturesListItem'
import FixtureListItemPlaceholder from '../../app/common/FixtureListItemPlaceholder';

export default function FixturesList({fixtures}) {

    if(!fixtures) 
    return (
      <>
        <FixtureListItemPlaceholder />
        <FixtureListItemPlaceholder />
      </>
    );

    return (
        <>
        {fixtures.map(fixture => (
            <FixtureListItem fixture={fixture} key={fixture.id}/>
            ))}
        </>
    )
}