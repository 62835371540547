import React, { useState } from "react";
import { Segment, Item, Button, Icon } from "semantic-ui-react";
import { approveRejectUser } from "../../app/firestore/firestoreService";
import { UserStatus } from "../../app/common/constants";

export default function RequestListItem({ request, removeFromList }) {
  const [loading, setLoading] = useState({ isLoading: false, target: null });

  async function handleOnClick(status, target) {
    setLoading({ isLoading: true, target: target });
    try {
      await approveRejectUser(status, request);
      removeFromList(request)
      setLoading({ isLoading: false, target: null });
    } catch (error) {
      setLoading({ isLoading: false, target: null });
      console.error(error);
    }
  }

  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header content={request.displayName} />
              <Item.Description>{request.userEmail}</Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment secondary>
        <span>
          <Icon name="clock" />
          {request.date.toLocaleString()}
        </span>
      </Segment>
      <Segment clearing>
        <Button.Group>
          <Button
            name="Reject"
            loading={loading.isLoading && loading.target === "Reject"}
            onClick={e => handleOnClick(UserStatus.REJECTED, e.target.name)}
            disabled={loading.isLoading}
            negative
          >
            Reject
          </Button>
          <Button.Or />
          <Button
            name="Approve"
            loading={loading.isLoading && loading.target === "Approve"}
            onClick={e => handleOnClick(UserStatus.APPROVED, e.target.name)}
            disabled={loading.isLoading}
            positive
          >
            Approve
          </Button>
        </Button.Group>
      </Segment>
    </Segment.Group>
  );
}
