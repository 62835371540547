import React from "react";
import { Container, Menu, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import SignedOutMenu from "./SignedOutMenu";
import SignedInMenu from "./SignedInMenu";
import { useSelector } from 'react-redux'


export default function NavBar() {
  const { authenticated } = useSelector(state => state.auth);

  return (
    <Menu inverted fixed="top">
      <Container>
        <Menu.Item as={NavLink} exact to="/" header>
        <Image
        size='mini'
        avatar
        spaced="right"
        src={"/assets/rpSmallLogo.png"}
      />
          RoysPredictions
        </Menu.Item>
        {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
      </Container>
    </Menu>
  );
}
