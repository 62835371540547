import React, { useState, Link } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, Divider } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";

export default function UnAuthModal({ history }) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  function handleClose() {
    history.goBack();
    setOpen(false);
  }

  return (
    <Modal open={open} size="small" onClose={handleClose}>
      <Modal.Header content="You need to login or register!" />
      <Modal.Content>
        <p>Please login to continue</p>
        <Button.Group widths={4}>
          <Button
            fluid
            color="blue"
            content="Login"
            onClick={() => dispatch(openModal({ modalType: "LoginForm" }))}
          />
          <Button.Or />
          <Button
            fluid
            color="green"
            content="Register"
            onClick={() => dispatch(openModal({ modalType: "RegisterForm" }))}
          />
        </Button.Group>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <a
            onClick={() =>
              dispatch(openModal({ modalType: "ResetPasswordForm" }))
            }
          >
            Forgot Password?
          </a>
        </div>
        <Divider />
        <div style={{ textAlign: "center" }}>
          <Button onClick={handleClose} content="Close" />
        </div>
      </Modal.Content>
    </Modal>
  );
}
