import {FETCH_FIXTURES} from './fixtureConstants'

const initialSate = {
  fixtures: []
};

export default function fixtureReducer(state = initialSate, { type, payload }) {
  switch (type) {
    case FETCH_FIXTURES:
      return {
        ...state,
        fixtures: payload
      }  
    default:
      return state;
  }
}
