import { combineReducers } from 'redux'
import predictionReducer from '../../features/predictions/predictoinReducer';
import testReducer from '../../features/sandbox/testReducer';
import modalReducer from '../common/modals/modalReducer';
import authReducer from '../../features/auth/authReducer';
import asyncReducer from '../async/asyncReducer';
import fixtureReducer from '../../features/fixtures/fixtureReducer';
import profileReducer from '../../features/profiles/profileReducer';
import leagueChatReducer from '../../features/chat/leagueChatReducer';
import currentSeasonReducer from '../common/currentSeasonReducer';
import leaderboardReducer from '../../features/leaderboard/leaderbaordReducer';

const rootReducer = combineReducers({
    test: testReducer,
    prediction: predictionReducer,
    fixture: fixtureReducer,
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    profile: profileReducer,
    currentSeason: currentSeasonReducer,
    chat: leagueChatReducer,
    leaderboard: leaderboardReducer
})

export default rootReducer;