import React from 'react'
import { Segment, Image, Item, Header } from 'semantic-ui-react';

export default function FixturesHeader({matchweek}) {
return(
    <Segment.Group>
    <Segment basic attached="top" style={{padding: '0'}}>
        <Image src={`/assets/plball.png`} fluid style={fixtureImageStyle}/>

        <Segment basic style={fixtureImageTextStyle}>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Header
                            size="huge"
                            content='Fixtures'
                            style={{color: 'white'}}
                        />
                        <p>Matchweek {matchweek}</p>
                    </Item.Content>
                </Item>
            </Item.Group>
        </Segment>
    </Segment>
</Segment.Group> 
)
}

const fixtureImageStyle = {
    filter: 'brightness(30%)',
    height: '17em'
};

const fixtureImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};