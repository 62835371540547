import React from "react";
import { Segment, Container, Image, Button, Icon } from "semantic-ui-react";
import config from "../../app/config/appConfig.json"

export default function HomePage({ history }) {
  return (
    <>
      <Segment inverted textAlign="center" vertical className="masthead">
        <Container>
          <div>
            <Image
              size="large"
              verticalAlign="middle"
              src="/assets/rpLogo.png"
            />
          </div>
          <div>
            <Button
              circular
              onClick={() => history.push("/fixtures")}
              size="big"
              color="green"
              style={{marginBottom: 90}}
            >
              Play
              <Icon name="right arrow" inverted />
            </Button>
          </div>
        </Container>
      </Segment>
      <Segment size="huge" style={{ margin: 0 }} textAlign="center">
        <Container>
          <p>
            Welcome to Roy’s Premier League Predictions. Do you enjoy the
            premier league and think you can out-predict others? If so, why not
            join my prediction league, it’s simple and easy to play.
          </p>
          <p>
            For more information, please email{" "}
            <text style={{ color: "blue" }}>
              {config.email}
            </text>
          </p>
        </Container>
      </Segment>
    </>
  );
}
