import React, { useState } from "react";
import { Icon, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function MobileNavBar() {
  const [activeItem, setActiveItem] = useState("");

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  return (
    <Menu fixed="bottom" icon="labeled" className="mobileNav" inverted fluid widths={4}>
      <Menu.Item
        as={Link}
        to="/fixtures"
        name="fixtures"
        position="center"
        active={activeItem === "fixtures"}
        onClick={handleItemClick}
      >
        <Icon name="soccer" />
      </Menu.Item>

      <Menu.Item
        position="center"
        name="predictions"
        as={Link}
        to="/predictions"
        active={activeItem === "predictions"}
        onClick={handleItemClick}
      >
        <Icon name="chart line" />
      </Menu.Item>

      <Menu.Item
        position="center"
        name="leaderboard"
        as={Link}
        to="/leaderboard"
        active={activeItem === "leaderboard"}
        onClick={handleItemClick}
      >
        <Icon name="table" />
      </Menu.Item>

      <Menu.Item
        position="center"
        as={Link}
        to="/leagueChat"
        name="chat"
        active={activeItem === "chat"}
        onClick={handleItemClick}
      >
        <Icon name="chat" />
      </Menu.Item>
    </Menu>
  );
}
