import React, { useState, useEffect } from "react";
import PredictionsList from "./PredictionsList";
import {
  listenToUserPredictionsFromFirestore,
  setupUserPredictionsInFirestore,
  getDataFromSnapshot
} from "../../app/firestore/firestoreService";
import {
  listenToPredictions,
  listenToPredictionsPoints
} from "./predictionActions";
import PredictionHeader from "./PredictionHeader";
import UserStatusComponent from "../../app/common/components/UserStatusComponent";
import { UserStatus } from "../../app/common/constants";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../../app/layout/loadingComponent";
import { getFixtures, getCurrentSeason } from "../../app/common/utils/utils";
import { getPredictionPoints } from "../../app/firestore/firebaseService";

//div with height 80 is a workground to create space between last item and bottom menu on iPhone

export default function PredictionsPage() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.auth);
  const { currentUserProfile } = useSelector(state => state.profile);

  const [fixtures, setFixtures] = useState(null);
  const [currentSeason, setCurrentSeason] = useState(null);

  useEffect(async () => {
    if(currentUserProfile.userStatus !== UserStatus.APPROVED) return

    let currentSeasonDeatils = await getCurrentSeason();
    setCurrentSeason(currentSeasonDeatils);
    let fixtures = await getFixtures();

    const unsubscribe = listenToUserPredictionsFromFirestore(
      currentUser?.userId,
      currentSeasonDeatils?.season,
      currentSeasonDeatils?.currentMatchweek
    ).onSnapshot(snapshot => {
      if (snapshot.data()) {
        let data = getDataFromSnapshot(snapshot);
        if (data.predictions) {
          dispatch(listenToPredictions(data.predictions));
        }
      } else {
        setupUserPredictionsInFirestore(
          currentUser?.userId,
          currentSeasonDeatils?.season,
          currentSeasonDeatils?.currentMatchweek
        );
      }
      setFixtures(fixtures);
    });

    const predictionPointsListnerRef = getPredictionPoints(
      currentUser?.userId,
      currentSeasonDeatils
    );
    predictionPointsListnerRef.on("value", snapshot => {
      if (!snapshot.exists()) return;
      dispatch(listenToPredictionsPoints(snapshot.val()));
    });

    return () => {
      predictionPointsListnerRef.off();
      unsubscribe();
    };
  }, []);

  if (currentUserProfile == null) {
    return <LoadingComponent />;
  } else if (currentUserProfile.userStatus !== UserStatus.APPROVED) {
    return <UserStatusComponent status={currentUserProfile.userStatus} />;
  }

  return (
    <>
      <PredictionHeader matchweek={currentSeason?.currentMatchweek} />
      <PredictionsList
        fixtures={fixtures}
        userId={currentUser?.userId}
        currentSeason={currentSeason}
      />
      <div style={{ height: 80 }}></div>
    </>
  );
}
