import config from "../../config/appConfig.json";
import { getLeaderboardUpdateMatchweek } from "../../firestore/firebaseService.js";

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function createDataTree(dataset) {
  let hashtable = Object.create(null);
  dataset.forEach(a => (hashtable[a.id] = { ...a, childNodes: [] }));
  let dataTree = [];
  dataset.forEach(a => {
    if (a.parentId) hashtable[a.parentId].childNodes.push(hashtable[a.id]);
    else dataTree.push(hashtable[a.id]);
  });
  return dataTree;
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export function getKickOffTime(date) {
  return (
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" : "") +
    date.getMinutes()
  );
}

export async function getFixtures() {
  return await fetch(`${config.footballAppUrl}/fixtures/PL`, {
    headers: {
      "Content-Type": "application/json",
      "X-AUTH-KEY": config.apiAuthKey,
      "X-USER-ID": config.appName
    }
  })
    .then(async response => {
      let fixtures = await response.json();
      switch (response.status) {
        case 200:
          return fixtures;
        case 404:
          return {
            errorCode: 404,
            message: `Issue getting fixture results`
          };
        case 500:
          return {
            errorCode: 500,
            message: `Error with service try again later`
          };
        default:
          return fixtures;
      }
    })
    .catch(e => console.log(e));
}

function getItemFromStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

function setItemInStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function getTodayDateUtc() {
  let date = new Date().toISOString();
  return Date.parse(date.split("T")[0]);
}

export async function getCurrentSeason() {
  // let currentSeasonDeatils = getItemFromStorage("currentSeasonDetails");

  // if (
  //   currentSeasonDeatils &&
  //   Date.parse(currentSeasonDeatils.lastFixtureDate) >= getTodayDateUtc()
  // ) {
  //   return currentSeasonDeatils;
  // } else {
    return await fetch(`${config.footballAppUrl}/current-season/PL`, {
      headers: {
        "Content-Type": "application/json",
        "X-AUTH-KEY": config.apiAuthKey,
        "X-USER-ID": config.appName
      }
    })
      .then(async response => {
        let data = await response.json();
        setItemInStorage("currentSeasonDetails", data);
        return data;
      })
      .catch(e => console.log(e));
  // }
}

export function sortLeaderboard(leaderboard, matchweekPoints, currentSeasonDeatils, leaderboardUpdatedWeek) {
  let leaderboardSorted = leaderboard.map(userData => {
    let userTotalMatchweekPoints = getUserMatchweekTotalPoints(userData.id, matchweekPoints)
    let userTotalPoints = getUserTotalPoints(userData.id, userData.points, matchweekPoints, currentSeasonDeatils.currentMatchweek, leaderboardUpdatedWeek)
    return {
      ...userData,
      plusPoints: userTotalMatchweekPoints,
      points: userTotalPoints,
    }
   }).sort((a, b) => {
    return sortByPoints(a, b) || sortByDisplayName(a, b);
  });
  return leaderboardSorted;
}

function getUserMatchweekTotalPoints(userId, matchweekPoints) {
  if (userId && matchweekPoints && matchweekPoints[userId]) {
    return matchweekPoints[userId].points;
  } else {
    return 0;
  }
}

function getUserTotalPoints(userId, totalPoints, matchweekPoints, currentMatchweek, leaderboardUpdatedWeek) {
  if (matchweekPoints && matchweekPoints[userId] && currentMatchweek != leaderboardUpdatedWeek) {
    return totalPoints + matchweekPoints[userId].points;
  } else {
    return totalPoints;
  }
}

export function sortByPoints(a, b) {
  let pointsA = a?.points || 0;
  let pointsB = b?.points || 0;

  return pointsB - pointsA;
}

function sortByDisplayName(a, b) {
  var nameA = a.displayName.toUpperCase();
  var nameB = b.displayName.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function sumObjValues(predictionPoints) {
  if (predictionPoints) {
    return Object.values(predictionPoints).reduce((a, b) => a + b);
  }
}
