import React from "react";
import { Segment, Grid } from "semantic-ui-react";
import { TeamLogos } from "../../app/common/constants";
import Scorecard from "./Scorecard";
import { renderFixtureStatus } from "../../app/common/components/fixtures";

export default function FixturesListItem({ fixture }) {
  function renderFixture(teamName, score, matchStatus) {
    return (
      <Segment style={{ backgroundColor: "#38003c" }} attached>
        <Grid verticalAlign="middle">
          <div
            style={{
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Grid.Column width={1}>
              <img
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  height: "2.7rem",
                  width: "2.7rem"
                }}
                src={TeamLogos[teamName]}
              />
            </Grid.Column>
          </div>
          <Grid.Column width={10}>
            <text style={{ fontSize: 20, color: "white", alignText: "center" }}>
              {teamName}
            </text>
          </Grid.Column>
          <Scorecard score={score} status={matchStatus} />
        </Grid>
      </Segment>
    );
  }

  let homeTeamName = fixture.homeTeam.name.replace(" FC", "");
  let awayTeamName = fixture.awayTeam.name.replace(" FC", "");
  let scores = fixture.score.fullTime;

  return (
    <>
      <Segment.Group>
        {renderFixture(homeTeamName, scores.homeTeam, fixture.status)}
        {renderFixture(awayTeamName, scores.awayTeam, fixture.status)}
        <Segment>{renderFixtureStatus(fixture)}</Segment>
      </Segment.Group>
    </>
  );
}
